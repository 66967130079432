<template >
    <div class="bg">
        <svg @click="closeAlbum()" class="bg__exit" xmlns="http://www.w3.org/2000/svg" height="48" width="48" fill="#FFF"><path d="m12.45 37.65-2.1-2.1L21.9 24 10.35 12.45l2.1-2.1L24 21.9l11.55-11.55 2.1 2.1L26.1 24l11.55 11.55-2.1 2.1L24 26.1Z"/></svg>
        <p class="counter">{{this.pos+1}}/{{this.gallery[this.album].length}}</p>
        
        <a direct="prev" @click="scrollPrev()" >
            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" fill="#FFF"><path d="M28.05 36 16 23.95 28.05 11.9l2.15 2.15-9.9 9.9 9.9 9.9Z"/></svg>
        </a>
        <a direct="next"  @click="scrollNext()">
            <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" fill="#FFF"><path d="m18.75 36-2.15-2.15 9.9-9.9-9.9-9.9 2.15-2.15L30.8 23.95Z"/></svg>
        </a>
        <section class="container" id="countbox">
            <div v-for="(pic, index) in gallery[album]" class="container__page" :id="`pic${index}`">
                <img :src="pic" alt="">
            </div>
            <!-- <div class="container__page" id="pic1">
                <img src="@/assets/images/hobby2.jpeg" alt="">
            </div>
            <div class="container__page" id="pic2">
                <img src="@/assets/images/hobby3.jpeg" alt="">
            </div>
            <div class="container__page" id="pic3">
                <img src="@/assets/images/hobby4.jpeg" alt="">
            </div>
            <div class="container__page" id="pic4">
                <img src="@/assets/images/hobby5.jpeg" alt="">
            </div>
            <div class="container__page" id="pic5">
                <img src="@/assets/images/hobby6.jpeg" alt="">
            </div>
            <div class="container__page" id="pic6">
                <img src="@/assets/images/hobby7.jpeg" alt="">
            </div>
            <div class="container__page" id="pic7">
                <img src="@/assets/images/hobby8.jpeg" alt="">
            </div> -->
        </section>
    </div>
</template>
<script>
export default {
    name: "Album",
    props:{
        album: "",
    },
    data(){
        return{
            pos: 0,
            length: 7,
            gallery: {
                community: [
                    require("@/assets/images/comm/community0.jpeg"),
                    require("@/assets/images/comm/community3.jpeg"),
                    require("@/assets/images/comm/community4.jpeg"),
                    require("@/assets/images/comm/community5.jpeg"),
                    require("@/assets/images/comm/community6.jpeg"),
                    require("@/assets/images/comm/community7.jpeg"),
                    require("@/assets/images/comm/community8.jpeg"),
                    require("@/assets/images/comm/community9.jpeg"),
                    require("@/assets/images/comm/community10.jpeg"),
                    require("@/assets/images/comm/community11.jpeg"),
                    require("@/assets/images/comm/community12.jpeg"),
                    require("@/assets/images/comm/community13.jpeg"),
                    require("@/assets/images/comm/community14.jpeg"),
                    require("@/assets/images/comm/community15.jpeg"),
                    require("@/assets/images/comm/community16.jpeg"),
                    require("@/assets/images/comm/community17.jpeg"),
                    require("@/assets/images/comm/community18.jpeg"),
                    require("@/assets/images/comm/community19.jpeg"),
                    require("@/assets/images/comm/community20.jpeg"),
                    require("@/assets/images/comm/community21.jpeg"),
                    require("@/assets/images/comm/community22.jpeg"),
                    require("@/assets/images/comm/community23.jpeg"),
                    require("@/assets/images/comm/community24.jpeg"),
                    require("@/assets/images/comm/community25.jpeg"),
                    require("@/assets/images/comm/community26.jpeg"),
                    require("@/assets/images/comm/community27.jpeg"),
                    require("@/assets/images/comm/community28.jpeg"),
                    require("@/assets/images/comm/community29.jpeg"),
                    require("@/assets/images/comm/community30.jpeg"),
                    require("@/assets/images/comm/community31.jpeg"),
                    require("@/assets/images/comm/community32.jpeg"),
                    require("@/assets/images/comm/community33.jpeg"),
                    require("@/assets/images/comm/community34.jpeg"),
                    require("@/assets/images/comm/community35.jpeg"),
                    require("@/assets/images/comm/community36.jpeg"),
                    require("@/assets/images/comm/community37.jpeg"),
                    require("@/assets/images/comm/community38.jpeg"),
                    require("@/assets/images/comm/community39.jpeg"),
                    require("@/assets/images/comm/community40.jpeg"),
                    require("@/assets/images/comm/community41.jpeg"),
                    require("@/assets/images/comm/community42.jpeg"),
                    require("@/assets/images/comm/community43.jpeg"),
                    require("@/assets/images/comm/community44.jpeg"),
                    require("@/assets/images/comm/community45.jpeg"),
                    require("@/assets/images/comm/community46.jpeg"),
                    require("@/assets/images/comm/community47.jpeg"),
                    require("@/assets/images/comm/community48.jpeg"),
                    require("@/assets/images/comm/community49.jpeg"),
                    require("@/assets/images/comm/community50.jpeg"),
                    require("@/assets/images/comm/community51.jpeg"),
                    require("@/assets/images/comm/community52.jpeg"),
                    require("@/assets/images/comm/community53.jpeg"),
                    require("@/assets/images/comm/community54.jpeg"),
                    require("@/assets/images/comm/community55.jpeg"),
                    require("@/assets/images/comm/community56.jpeg"),
                    require("@/assets/images/comm/community57.jpeg"),
                    require("@/assets/images/comm/community58.jpeg"),
                    require("@/assets/images/comm/community59.jpeg"),
                    require("@/assets/images/comm/community60.jpeg"),
                    require("@/assets/images/comm/community61.jpeg"),
                    require("@/assets/images/comm/community62.jpeg"),
                    require("@/assets/images/comm/community63.jpeg"),
                    require("@/assets/images/comm/community64.jpeg"),
                    require("@/assets/images/comm/community65.jpeg"),
                    require("@/assets/images/comm/community66.jpeg"),
                    require("@/assets/images/comm/community67.jpeg"),
                    require("@/assets/images/comm/community68.jpeg"),
                    require("@/assets/images/comm/community69.jpeg"),
                    require("@/assets/images/comm/community70.jpeg"),
                    require("@/assets/images/comm/community71.jpeg"),
                    require("@/assets/images/comm/community72.jpeg"),
                    require("@/assets/images/comm/community73.jpeg"),
                    require("@/assets/images/comm/community74.jpeg"),
                    require("@/assets/images/comm/community75.jpeg"),
                    require("@/assets/images/comm/community76.jpeg"),
                    require("@/assets/images/comm/community77.jpeg"),
                    require("@/assets/images/comm/community78.jpeg"),
                    require("@/assets/images/comm/community79.jpeg"),
                    require("@/assets/images/comm/community80.jpeg"),

                ],

                media: [
                    require("@/assets/images/media/media0.jpeg"),
                    require("@/assets/images/media/media1.jpeg"),
                    require("@/assets/images/media/media2.jpeg"),
                    require("@/assets/images/media/media3.jpeg"),
                    require("@/assets/images/media/media4.jpeg"),
                    require("@/assets/images/media/media5.jpeg"),
                    require("@/assets/images/media/media6.jpeg"),
                    require("@/assets/images/media/media7.jpeg"),
                    require("@/assets/images/media/media8.jpeg"),
                    require("@/assets/images/media/media9.jpeg"),
                    require("@/assets/images/media/media10.jpeg"),
                    require("@/assets/images/media/media11.jpeg"),
                    require("@/assets/images/media/media12.jpeg"),
                    require("@/assets/images/media/media13.jpeg"),
                    require("@/assets/images/media/media14.jpeg"),
                    require("@/assets/images/media/media15.jpeg"),
                    require("@/assets/images/media/media16.jpeg"),
                    require("@/assets/images/media/media17.jpeg"),
                    require("@/assets/images/media/media18.jpeg"),
                    require("@/assets/images/media/media19.jpeg"),
                    require("@/assets/images/media/media20.jpeg"),
                    require("@/assets/images/media/media21.jpeg"),
                    require("@/assets/images/media/media22.jpeg"),
                    require("@/assets/images/media/media23.jpeg"),
                    require("@/assets/images/education/edu0.jpeg"),
                    require("@/assets/images/education/edu1.jpeg"),
                    require("@/assets/images/education/edu2.jpeg"),
                    require("@/assets/images/education/edu3.jpeg"),
                    require("@/assets/images/education/edu4.jpeg"),
                    require("@/assets/images/education/edu5.jpeg"),
                    require("@/assets/images/education/edu6.jpeg"),
                    require("@/assets/images/education/edu7.jpeg"),
                    require("@/assets/images/education/edu8.jpeg"),
                    require("@/assets/images/education/edu9.jpeg"),
                    require("@/assets/images/education/edu10.jpeg"),
                    // require("@/assets/images/media/media2.jpeg"),
                ],

                education: [
                    require("@/assets/images/education/edu0.jpeg"),
                    require("@/assets/images/education/edu1.jpeg"),
                    require("@/assets/images/education/edu2.jpeg"),
                    require("@/assets/images/education/edu3.jpeg"),
                    require("@/assets/images/education/edu4.jpeg"),
                    require("@/assets/images/education/edu5.jpeg"),
                    require("@/assets/images/education/edu6.jpeg"),
                    require("@/assets/images/education/edu7.jpeg"),
                    require("@/assets/images/education/edu8.jpeg"),
                    require("@/assets/images/education/edu9.jpeg"),
                    require("@/assets/images/education/edu10.jpeg"),

                ],

                youth:[
                    require("@/assets/images/youth/youth0.jpeg"),
                    require("@/assets/images/youth/youth1.jpeg"),
                    require("@/assets/images/youth/youth2.jpeg"),
                    require("@/assets/images/youth/youth3.jpeg"),
                    require("@/assets/images/youth/youth4.jpeg"),
                    require("@/assets/images/youth/youth5.jpeg"),
                    require("@/assets/images/youth/youth6.jpeg"),
                    require("@/assets/images/youth/youth7.jpeg"),
                    require("@/assets/images/youth/youth8.jpeg"),
                    require("@/assets/images/youth/youth9.jpeg"),
                    require("@/assets/images/youth/youth10.jpeg"),
                    require("@/assets/images/youth/youth11.jpeg"),
                    require("@/assets/images/youth/youth12.jpeg"),
                    require("@/assets/images/youth/youth13.jpeg"),
                    require("@/assets/images/youth/youth14.jpeg"),
                    require("@/assets/images/youth/youth15.jpeg"),
                    require("@/assets/images/youth/youth16.jpeg"),
                    require("@/assets/images/youth/youth17.jpeg"),
                    require("@/assets/images/sports/sport0.jpeg"),
                    require("@/assets/images/sports/sport1.jpeg"),
                    require("@/assets/images/sports/sport2.jpeg"),
                    require("@/assets/images/sports/sport3.jpeg"),
                    require("@/assets/images/sports/sport4.jpeg"),
                    require("@/assets/images/sports/sport5.jpeg"),
                    require("@/assets/images/sports/sport6.jpeg"),
                    require("@/assets/images/sports/sport7.jpeg"),
                    require("@/assets/images/sports/sport8.jpeg"),
                ],

                sport: [
                    require("@/assets/images/sports/sport0.jpeg"),
                    require("@/assets/images/sports/sport1.jpeg"),
                    require("@/assets/images/sports/sport2.jpeg"),
                    require("@/assets/images/sports/sport3.jpeg"),
                    require("@/assets/images/sports/sport4.jpeg"),
                    require("@/assets/images/sports/sport5.jpeg"),
                    require("@/assets/images/sports/sport6.jpeg"),
                    require("@/assets/images/sports/sport7.jpeg"),
                    require("@/assets/images/sports/sport8.jpeg"),
                  
                ]
            },
            
        }
    },
   
    methods:{
        closeAlbum(){
            this.$emit("closealbum");
        },
        scrollPrev(){
            let leftOffset = document.getElementById("countbox").scrollLeft;
            let slideWidth = document.getElementById("pic0").offsetWidth;

            if(leftOffset%slideWidth == 0){
                this.pos = document.getElementById("countbox").scrollLeft / document.getElementById("pic0").offsetWidth;
                this.pos -= 1;
                if(this.pos == this.gallery[this.album].length){
                    this.pos = 0
                }else if(this.pos == -1){
                    this.pos = this.gallery[this.album].length-1;
                }
                document.getElementById(`pic${this.pos}`).scrollIntoView({behavior: 'smooth'})
            }
            
        },
        scrollNext(){
            let leftOffset = document.getElementById("countbox").scrollLeft;
            let slideWidth = document.getElementById("pic0").offsetWidth;

            if(leftOffset%slideWidth == 0){
                this.pos = document.getElementById("countbox").scrollLeft / document.getElementById("pic0").offsetWidth;
                this.pos += 1;
                if(this.pos == this.gallery[this.album].length){
                    this.pos = 0
                }else if(this.pos == -1){
                    this.pos = this.gallery[this.album].length-1;
                }
                document.getElementById(`pic${this.pos}`).scrollIntoView({behavior: 'smooth'})
            }
            
        }
    },
    mounted(){
        document.addEventListener("keydown", ({key}) => {
            if (key === "Escape"){
                this.closeAlbum()
            }
        });
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles.scss';

.bg{
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10;

    &__exit{
        position: absolute;
        top: 30px;
        right: 50px;
        z-index: 15;
        &:hover{
            cursor: pointer;
        }
        
    }
    & > a{
        z-index: 12;
        width: fit-content;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        transform: translate(0px, -50%);
        &:hover{
            cursor: pointer;
            background-color: rgba(128, 128, 128, 0.123);
        }

    }
    & > a[direct="prev"]{
        left: calc(((100% - 600px) / 2) - 100px);;
        
    }
    & > a[direct="next"]{
        right: calc(((100% - 600px) / 2) - 100px);
        
    }
    

}
.container::-webkit-scrollbar {
    display: none;
}

.counter{
    color: white;
    position: absolute;
    top: 20px;
    left: 50px;
}

/* Hide scrollbar for IE, Edge and Firefox */
.container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.container{
    width: 100vw;
    height: 100vh;
    overflow-x: scroll;
    overflow-y: hidden;
    // scroll-snap-coordinate: 0 0;

    // scroll-snap-points-x: repeat(100%);
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
    display:flex;
    justify-content: stretch;
    align-items: center;
    -webkit-overflow-scrolling: touch;
    // gap: calc(100% - 500px);
    &__page{
        position: relative;
        width: 100vw;
        height: 100vh;
        flex:0 0 100%;
        scroll-snap-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        & > img{
            width: 90%;
            // max-height: 500px;
            max-width: 600px;
            user-select: none;
            -webkit-user-select: none;
            
            
        }
       
    }

}
@media only screen and (max-width: 900px){
    .bg{
        & > a{
            display: none;
            }

        // }
        // & > a[direct="prev"]{
        //     right: calc(50% + 325px);
            
        // }
        // & > a[direct="next"]{
        //     left: calc(50% + 325px);
            
        // }
    }
}
@media only screen and (max-width: 400px){
    .bg{
        & > svg{
            top: 30px;
            right: 30px;
        }
    }

    .container__page{
        & > img{
            transform: translateY(-60px);
        }
    }
}
</style>