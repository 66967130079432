<template>
    <section class="content-padding">
        <!-- <h1>DONATEEEE</h1> -->
        <!-- <p class="text">我们需要您的财政支持才能赢得竞选，这也是个人对确保我们民主系统运作的一种贡献，帮助 Yan Wang 为万锦第二区市议员的所有居民努力工作。</p> -->
        <p class="text" v-if="lang == 0">Contributions from individuals ensures our democratic system works.</p>
        <p class="text" v-if="lang == 0">We need your financial support and the support of many individuals for a winning campaign. Help Yan Wang to continue to work hard for all residents of Markham Ward 2.</p>
        <p class="text"><span class="text__head">{{translate.note[lang][0]}}</span>{{translate.note[lang][1]}}</p>
        <p class="text"><span class="text__head">{{translate.cheque[lang]}}</span>Yan Wang Campaign Ward 2 Markham</p>
        <p class="text"><span class="text__head">{{translate.mobile[lang]}}</span>Yan Wang Campaign Ward 2 Markham</p>
        <p class="text"><span class="text__head">{{translate.email[lang]}}</span>yan@yanwangmarkham.ca</p>
        <h1>{{translate.instructions[lang]}}</h1>
        <p class="text" v-if="lang == 0">The City of Markham offers rebates to Markham residents who make a donation to Council candidates. You can receive a rebate for 75% of your donation to a maximum of $150. (After your rebate a $200 donation will cost you only $50).</p>
        <ul>
            <li v-for="point in translate.points">{{point[lang]}}</li>
            <!-- <li>万锦市的选民和永久居民可获得万锦市政府75%返还，最高返款额$150</li> -->
        </ul>
        <p class="text">{{translate.example[lang]}}</p>
        <p class="text">{{translate.caution[lang]}}</p>

        <p class="text">{{translate.chartdesc[lang]}}</p>

        <table>
        <tr>
            <th>{{translate.chartcol1[lang]}}</th>
            <th>{{translate.chartcol2[lang]}}</th>
            <th>{{translate.chartcol3[lang]}}</th>
        </tr>
        <tr>
            <td>$100</td>
            <td>$75</td>
            <td>$25</td>
        </tr>
        <tr>
            <td>$200</td>
            <td>$150</td>
            <td>$50</td>
        </tr>
        <tr>
            <td>$300</td>
            <td>$150</td>
            <td>$150</td>
        </tr>
        <tr>
            <td>$500</td>
            <td>$150</td>
            <td>$350</td>
        </tr>
        <tr>
            <td>$1200</td>
            <td>$150</td>
            <td>$1050</td>
        </tr>
        </table>

        <p class="text" v-if="lang == 0">Please feel free to contact us for more information.</p>
        <p class="text" v-if="lang == 0">Email:  yan@yanwangmarkham.ca<br>Tel: 647-929-2689</p>
        <!-- <p class="text">详见万锦市网站有关返还金额的更多信息：<a href="https://www.electionsmarkham.ca/en/candidates/donating-to-a-candidate-s-campaign/">https://www.electionsmarkham.ca/en/candidates/donating-to-a-candidate-s-campaign/</a> </p> -->
        <p class="text">{{translate.thanks[lang]}}</p>

        <p class="text" v-if="lang == 0">Read more about the rebate on the city of Markham website <a href="https://www.electionsmarkham.ca/en/candidates/donating-to-a-candidate-s-campaign/" target="_blank">here</a></p>
   
    </section>
</template>
<script>
import translate from '@/assets/translate.json';

export default {
    name: "Donate",
    components:{

    },
    props: {
        lang: 0,
    },
    data(){
        return{
            translate: translate.donate
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../assets/styles.scss';
h1{
    text-align: left;
    margin-top: 20px;
    color: $primary-color;
}
p:not(:first-child){
    margin-top: 20px;
}
.text{
    font-size: $paragraph;
    text-align: left;
    
    &__head{
        font-weight: 600;
    }

}
ul{
    font-size: $paragraph;
    text-align: left;
    padding-left: 40px;

}
th{
    text-align: center;
    font-size: $paragraph;
    border: 1px solid #dddddd;
    padding: 8px 20px;
    color: white;
    
}
td{
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px 20px;
  font-size: $paragraph;
  width: 200px;
  font-weight: 600;

}

tr:nth-child(even) {
  background-color: $title-color;
  color: rgb(0, 0, 0);
  filter: drop-shadow(0,0,1px,black);
}
tr:nth-child(odd) {
  background-color: $primary-color;
  color: white;
}
table{
    margin: auto;
    margin-top: 20px;
}



@media only screen and (max-width: 500px){
    th{
        font-size: 18px;
        padding: 8px 10px;
    }
    td{
         font-size: 20px;
        // padding: 8px 10px;
    }

}
</style>