<template>
    <section class="intro content-padding">

        <h1>“{{translate[`msg${lang}`].title}}”</h1>
        <p v-for="text in translate[`msg${lang}`].text">{{text}}</p>
        
    </section>
</template>
<script>
import translate from "@/assets/translate.json";

export default {
    name: "Intro",
    props: {
        lang: 0,
    },
    data(){
        return{
            translate: translate.intro
        }
    }

}
</script>
<style lang="scss" scoped>
@import '../../assets/styles.scss';

.intro{
    background-color: $background-color;
    & > h1{
        color: $primary-color;
        font-size: $heading1;
    }

    & > p{
        font-size: $paragraph;
        text-align: left;
        margin-top: 20px;
    }
}
</style>