<template>
    <main class="content-padding">

        <div class="voteactions" >
            <button class="startvote" @click="voteLink()">選民註冊</button>
            <!-- <button class="votesteps" @click="openPDF()"><img src="https://www.iconpacks.net/icons/2/free-pdf-download-icon-3388-thumb.png"><p>选民信息更新步骤</p></button>  -->
        </div>
        <p>2022年10月14日至10月24日，Markham 萬錦市線上和線下的投票渠道同時對選民開放。</p>
        <div class="divider"></div>
        <h1>方式一：線上投票</h1>
        <p>10月14日至24日早10點至晚8點，使用個人電子設備(手機、電腦、平板電腦等)，或萬錦市圖書館提供的電腦進行投票。</p>
        <a href="https://markham.bibliocommons.com/locations/?_ga=2.113146124.965856506.1649172318-1956973357.1649172318">查詢圖書館營業時間</a>
        
        <div class="divider"></div>
        <h1>方式二：最後投票日之前（線下投票）</h1>
        <p>10月20日（星期四）至10月22日(星期六）早10點至晚8點，在萬錦市指定的投票處親自投票。</p>

        <div class="divider"></div>
        <h1>方式三：最後投票日當天 （線上投票）</h1>
        <p>10月24日(星期一)為最後投票日。可以在早10點至晚8點期間，使用個人電子設備、萬錦市圖書館提供的電腦、或者在指定的投票處使用投票專用觸屏設備進行線上投票</p>
        <a href="https://markham.bibliocommons.com/locations/?_ga=2.113146124.965856506.1649172318-1956973357.1649172318 ">（指定投票地點查詢）</a>


        <div class="divider"></div>
        <h1>方式四：在Markham市選舉辦公室（線下投票）</h1>
        <p>10月14日至10月22日期間，通過預約在早10點至晚8點期間，在Markham Civic Centre 進行線下投票。</p>
        <p>地址和聯繫方式：</p>
        <p>Markham Civic Centre<br>101 Town Centre Blvd, Markham, ON L3R 9W3<br>Tel: (905) 477-7000</p>
        <!-- <a href="@/assets/voteStepsSimp.pdf" target="_blank"><button class="votesteps">投票步骤</button></a> -->
        <!-- <button class="votesteps" @click="openPDF()"><img src="https://www.iconpacks.net/icons/2/free-pdf-download-icon-3388-thumb.png"><p>选民信息更新步骤-></p></button>
        <button class="startvote" @click="voteLink()">投票注册</button> -->
    </main>
</template>
<script>


export default {
    name: "HowtovoteTrad",
    methods:{
        openPDF(){
            window.open("https://www.yanwangmarkham.ca/svote.pdf")
        },
        voteLink(){
            window.open("https://www.electionsmarkham.ca/en/voting/register-to-vote/")
        }
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles.scss';
.divider{
    height: 20px;
    width: 100%;
}
h1{
    text-align: left;
    color: $primary-color;
    font-size: $heading1;
    
}
ul{
    text-align: left;
    font-size: $paragraph;
    padding-left: 25px;
}
p{
    text-align: left;
}
a{
    text-decoration: none;
    text-align: left;
    display: block;

}

.votesteps{
    @include button;
    width: fit-content;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: all .3s;
    padding: 10px 20px;
    // &:hover{
    //     cursor: pointer;
    //     color: $primary-color-hover;
    //      background-color: $title-color;
    // }
    // &:hover img{
    //     transform: none;
    // }
    // &:hover p{
    //     transform: none;
    // }
    color: white;
    background-color: $title-color;

    & > img{
        width: 35px;
        display: block;
        // transform: translateX(-20px);
        // transition: all .3s;

    }
    & > p{
        // transform: translateX(-20px);
        transition: all .3s;
    }

    &:hover{
        cursor: pointer;

    }
    &:hover p{
        color: $primary-color;
        
    }

}

.voteactions{
    display: flex;
    gap: 20px;
    padding-bottom: 20px
}

.startvote{
    @include button;
    background-color: $primary-color;
    border: none;
    outline: none;

    color: white;

    &:hover{
        background-color: #015d5a;
        cursor: pointer;
    }

}

@media only screen and (max-width: 550px){
    .voteactions{
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
        padding-bottom: 20px
    }
    .startvote{
        width: 220px;
    }
    .votesteps{
        & > img{
            display: none;
        }
    }
}
</style>