<template>
    <main class="content-padding-top-only">
        <section class="options ">
            <h1 :class="[selected == 0 ? 'selected' : 'unselected']" @click="switchTab(0)">{{volunteer[lang]}}</h1>
            <h1 :class="[selected == 1 ? 'selected' : 'unselected']" @click="switchTab(1)" v-if="lang == 0">Request<br>Lawn Sign</h1>
            <h1 :class="[selected == 1 ? 'selected' : 'unselected']" @click="switchTab(1)" v-else>{{lawn[lang]}}</h1>
            <h1 :class="[selected == 2 ? 'selected' : 'unselected']" @click="switchTab(2)">{{donate[lang]}}</h1>
        </section>
        <section class="contents">
        
        
        
        <transition name="fade">
            <Volunteer :lang="lang" v-if="selected == 0"/>
        </transition>
        <transition name="fade">
            <Lawn v-if="selected == 1" :lang="lang"/>
            
        </transition>
        <transition name="fade">
            <Donation v-if="selected == 2" :lang="lang"/>
            
        </transition>

        
        
        
        </section>
    </main>
</template>
<script>
import Donation from '@/components/support/donateinfo.vue';
import Volunteer from '@/components/support/Volunteer.vue';
import Lawn from '@/components/support/Lawn.vue';
export default {
    name: "SupportParent",
    props:{
        lang: 0,
    },
    components:{
        Donation,
        Volunteer,
        Lawn
    },
    data(){
        return{
            selected: 0,
            pages: ["volunteer", "requestlawnsign", "donate"],

            volunteer: ["Get Involved", "加入我們", "加入我们"],
            lawn: ["Request Lawn Sign", "草地插牌", "草地插牌"],
            donate: ["Donate", "捐款", "为艳捐款"]
        }
    },
    methods:{
        switchTab(tab){
            this.selected = tab;
            if(this.lang == 1){
                this.$router.replace({ path: '/ct/support', query: {page: this.pages[tab]} });
            }else if(this.lang == 2){
                this.$router.replace({ path: '/zh/support', query: { page: this.pages[tab] }})
            }else{
                 this.$router.replace({ path: '/support', query: {page: this.pages[tab] } });
            }
            
        },
        loadTab(page){
            if(page == "volunteer"){

            }
        }
    },
    mounted(){
        if(!this.$route.query.page){
            this.switchTab(0);
        }else{
            this.selected = this.pages.indexOf(this.$route.query.page);
        }
        
       
  
    },
    created() {
         
    },

}
</script>
<style lang="scss" scoped>
@import '@/assets/styles.scss';

main{
    background-color: $background-color;
    // transition: all .5s;
}
.unselected{
    color: grey;
    border-bottom: solid 3px rgb(205, 205, 205);
    // &:hover{
    //     cursor: pointer;
    //     color: $primary-color;
    //     border-bottom: solid 3px $primary-color;

    // }
}
.selected{
    color: $primary-color;
    border-bottom: solid 5px $primary-color;
}
.options{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;



    & > h1{
        text-align: center;
        font-size: $heading1;
        height: fit-content;
        width: calc((100% - 240px) / 3);
        line-height: 100%;
        // padding: 10px 30px 0px 30px;
        -webkit-transition: all 300ms;
        -webkit-transition: -webkit-transform 300ms;
        -moz-transition: all 300ms;
        transition: all .3s;
        
        &:hover{
        cursor: pointer;
        // color: $primary-color;
        // border-bottom: solid 3px $primary-color;

    }

        
        
    }
}

.fade-enter-from{
    opacity: 0;
}
.fade-enter-to{
    opacity: 1;
}
.fade-enter-active{
    -webkit-transition: opacity 500ms ease;
    -webkit-transition: -webkit-transform 500ms;
    -moz-transition: opacity 500ms ease;
    transition: opacity 0.5s ease;
    
}

// .fade-leave-from{
//     opacity: 1;
// }
// .fade-leave-to{
//     opacity: 0;
// }
// .fade-leave-active{
//     transition: opacity 1s ease;
// }


@media only screen and (max-width: 850px){
    .options{
        & > h1{
            font-size: $paragraph;
        }
    }
}
@media only screen and (max-width: 600px){
    .options{
        gap: 10px;
        & > h1{
            width: 150px;
            font-size: $paragraph;
            padding: 10px 20px 0px 20px;
        }
    }
}
@media only screen and (max-width: 500px){
    .options{
        gap: 0px;
        & > h1{
            font-size: $paragraph;
            width: 120px;
            padding: 10px 0px 0px 0px;
            
            // padding: 10px 10px 0px 10px;
        }
    }
}
</style>