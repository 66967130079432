<template>
    <section class="content-padding">
        <h1>{{translate.title[lang]}}</h1>
        <p>{{translate.desc[lang]}}</p>
        <img src="@/assets/images/ward2mapB.png" alt="">

        <p style="margin-top: 10px">{{translate.phone[lang]}}</p>
        <p>{{translate.email[lang]}}</p>

        <h1 class="formtitle">{{translate.form.title[lang]}}</h1>

        <form class="lawnform" id="lawnform" v-if="status == 0">
            <label class="lawnform__label" for="" style="margin-top: 20px;">{{translate.form.personal[lang]}}</label>
            <section class="lawnform__cred">
                <input type="text" class="form__input" id="firstname" :placeholder="translate.form.fn[lang]" v-model="firstn" required>
                <input type="text" class="form__input" id="lastname" :placeholder="translate.form.ln[lang]" v-model="lastn" required>
            </section>
            <section style="margin-top: 10px;">
                <input type="email" class="form__input" id="emailcontact" :placeholder="translate.form.email[lang]" v-model="email" required>
            </section>
            <section style="margin-top: 10px;">
                <input type="tel" class="form__input" id="phone" :placeholder="translate.form.phone[lang] + ' Eg. (555) 555 - 5555'" @input="phoneInput($event)" v-model="phonenumber" required>
            </section>
            <label class="lawnform__label" for="" style="margin-top: 20px;">{{translate.form.address[lang]}}</label>
            <section>
                <input type="text" class="form__input" id="address" placeholder="Eg. 123 Main Street" v-model="address" required>
            </section>
            <section style="margin-top: 10px;">
                <input type="text" class="form__input" id="postal" :placeholder="translate.form.postal[lang]" v-model="postal" required>
            </section>
             <section style="margin-top: 10px;">
                <textarea type="text" :placeholder="translate.form.remarks[lang]" v-model="remark" class="form__input"></textarea>
            </section>
            <section class="lawnform__agree">
                <input type="checkbox" id="agreement" class="cb" required>
                <label for=""> *By filling this form, I authorize the Yan Wang Campaign Office to put the lawn sign on my lawn from Sept 26 - Oct 24, 2022.<br>**Yan Wang Campaign Office will remove the sign within 48 Hours after the election (Oct 24, 2022).</label>
            </section>
            <button type="submit" class="form__button" @click="submitRequest()">
                <div v-if="processing" class="lds-ring"><div></div><div></div><div></div><div></div></div>
                <p v-else>{{translate.form.submit[lang]}}</p>
            </button>
        </form>
        <p class="tymsg" v-if="status == 1">Thanks for showing your support!</p>

    </section>
</template>
<script>
import translate from '@/assets/translate.json';

export default {
    name: "Lawn",
    components:{

    },
    props: {
        lang: 0,
    },
    data(){
        return{
            translate: translate.lawn,
            phonenumber: "",
            firstn: "",
            lastn: "",
            email: "",
            address: "",
            postal: "",
            remark: "",
            processing: false,
            status: 0
        }
    },
    methods:{
        formatPhoneNumber(value){
            if (!value) return value;
            const phoneNumber = value.replace(/[^\d]/g, '');
            const phoneNumberLength = phoneNumber.length;
            if (phoneNumberLength < 4) return phoneNumber;
            if (phoneNumberLength < 7) {
                return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
            }
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3,6)}-${phoneNumber.slice(6, 10)}`;
        },
        phoneInput(e){
            // const inputField = document.getElementById('phone-number');
            const formattedInputValue = this.formatPhoneNumber(this.phonenumber);
            this.phonenumber = formattedInputValue;
        },
        submitRequest(){
            if(!this.processing && this.valadility()){
                this.processing = true;
                // this.status = 1
                let info = {
                    firstName: this.firstn,
                    lastName: this.lastn,
                    email: this.email,
                    phone: this.phonenumber,
                    address: this.address,
                    postal: this.postal,
                    remarks: this.remark
                }
                fetch("https://wangyan-server.herokuapp.com/lawnrequest", {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(info)}).then(res => {return res.json()}).then(data => {
                    this.processing = false;
                    this.status = 1
                })
            }
        },
        valadility(){
            if(document.querySelector("#emailcontact").checkValidity() && document.querySelector("#firstname").checkValidity() && document.querySelector("#lastname").checkValidity() && document.querySelector("#phone").checkValidity() && document.querySelector("#address").checkValidity() && document.querySelector("#postal").checkValidity() && document.querySelector("#agreement").checkValidity()){
                return true
            };
            return false
        }
    },
    mounted(){
        var form = document.querySelector("#lawnform");
        form.addEventListener('submit', (event) => {
            event.preventDefault();
        });
    }

}
</script>
<style lang="scss" scoped>
@import '../../assets/styles.scss';
h1{
    text-align: center;
    color: $primary-color;
}
img{
    width: 80%;
    max-width: 400px;
    margin-top: 20px;
}
p{
    text-align: left;
}
.cb{
    width: 25px;
    height: 25px;
    appearance: auto;
}
.formtitle{
    margin-top: 30px;
    font-size: $heading1;
}
.lawnform{
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    &__label{
        display: block;
        text-align: left;
        margin-left: 0;
        font-size: calc($paragraph - 5px);
        font-weight: 600;
    }

    & > button{
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        font-size: $paragraph;
        & > p{
            text-align: center;
        }
    }
    

    &__cred{
        display: flex;
        gap: 20px;
    }

    &__agree{
        margin-top: 20px;

        & > label{
            font-size: calc($paragraph - 3px);
            display: block;
            text-align: left;
        }
        & > input{
            float: left;
            margin-right: 10px;
        }
    }

    & > section{
        & > input{
            color: #015d5a;
            padding: 10px 0px 10px 0px;
        }

        & > textarea{
            color: #015d5a;
            padding: 10px 0px 10px 0px;
        }
    }

    
}

.tymsg{
    text-align: center;
    margin-top: 20px;
}











.lds-ring {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 35px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 4px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>