<template>
    <section class="container content-padding">

        <!-- <h1 class="about__text--title">吾心安处，便是吾乡.</h1> -->
        <section class="containertext">
            <div class="containertext__img" :style="{'background-image': 'url(' + require('@/assets/images/family1.jpeg') + ')'}"></div>
            <div class="containertext__text">
                <h1 class="about__text--title">我愛萬錦，我愛我家</h1>
                <p class="about__text--content">大家好，我是萬錦第二區市議員候選人，王艷。23年前，先生和我遠涉重洋，選擇在萬錦市定居。</p>
                <!-- <p class="about__text--content"></p> -->
                <p class="about__text--content">和無數新移民一樣，我們在兼顧家庭的同時，創辦了自己的貿易公司積極在商界發展。迎來两个寶貝女後，我成爲全職媽媽，希望用更多的时间去陪伴她们成长。从大女儿讀書起，我開始了支持女兒班級和學校活動的義工生涯。正是這些義工機會，令我體會到自己對特殊教育的熱愛，成為了一名特殊教育工作者。</p>
            </div>
        </section>

        <!-- <p class="about__text--content">随着两个女儿的相继出生，我开始转入家庭，用更多的时间去陪伴她们成长。从大女儿走进学校起，我开始了支持女儿班级和学校活动的义工生涯。正是这些义工机会，让我体会到自己对特殊教育的热爱，成为了一名特殊教育工作者。</p> -->
         
        <div style="width: 100%; height: 25px"></div>

         <section class="containertext" type="right">
            <div class="containertext__img" :style="{'background-image': 'url(' + require('@/assets/images/yanteacher.png') + ')'}"></div>
            <div class="containertext__text">
                <h1 class="about__text--title">從自主創業到從事特殊教育，從主持人到專業媒體人，從義工到社區領袖</h1>
                <p class="about__text--content">從約克區教育局，到杜咸區天主教教育局和杜咸區教育局，再到多倫多教育局，在服務眾多從幼稚園班到高中各年級的有特殊需要的學生群體時，我深刻了解到每個個體獨特的需求和境遇，在處理每個獨特複雜的個案時，以愛為初心，以關懷和關注為己任，運用豐富的專業知識，努力幫助他（她）們發展和改變。近10年的特殊教育經歷，讓我深刻體會特殊教育的重要性和從事這項工作的意義所在。</p>
            </div>
        </section>
        
        <div style="width: 100%; height: 25px"></div>
        
        <section class="containertext" type="left">
            <div class="containertext__img" :style="{'background-image': 'url(' + require('@/assets/images/aboutleader.jpg') + ')'}"></div>
            <div class="containertext__text">
                
                <p class="about__text--content">正是服務特殊群體的這些經歷，更加激勵我加入各種義工團隊，深入社區，服務民眾。我自2015年起擔任華僑大學加拿大校友會秘書長，領導團隊統籌各種大型體育文娛活動，積極參與各類社會慈善事業，“矢志服務成就美麗人生”的義工理念根植我心。</p>
                <!-- <p class="about__text--content">2017年 ，我加盟“魅力中国” 国语电台， 成为一名专业电台节目主持人。 通过与行业专家和社区人士的空中交流，共同分享加拿大多元文化带来的自由民主和社会融合。</p> -->
                
            </div>
        </section>

        <div style="width: 100%; height: 25px"></div>

        <section class="containertext" type="right">
            <div class="containertext__img" :style="{'background-image': 'url(' + require('@/assets/images/endorse1.jpeg') + ')'}"></div>
            <div class="containertext__text">
                
                
                <p class="about__text--content">2017年 ，我加盟“魅力中國”電臺， 成為一位專業電台节目主持人。透過與行業專家同社區人士的交流，共同分享加拿大多元文化帶來的自由民主和社會共融。</p>
                <p class="about__text--content">作為媒體工作者，我跟踪報導歷次三級政府的選舉和社會時政，深諳政府運作機制，充分體察民情民願。歷經歲月浸染，閱儘百態人生，我深刻體會到，四方奔忙抵不過一捧人間煙火。熱愛家庭，服務他人，回饋社會，才不枉一場豐盈人生。</p>
            </div>
        </section>

        
        <div style="width: 100%; height: 25px"></div>
       
        
        
        <p class="about__text--content txtbold" style="text-align: center">我是王艷，萬錦第二區市議員候選人<br>未忘初衷，未負流年<br>胸中萬千丘壑，眼裡星辰大海。<br>此刻，時光正好。我，定不負您的期待！</p>  
       
        <p class="about__text--content txtbold" style="font-size: 30px; text-align: center">选我，定不负您的期望！ </p>    
    </section>
</template>
<script>
export default {
    name: "AboutMe",
    data(){
        return{
            aboutme: ["About Me", "關於我", "关于我"]
        }
    },
    props: {
        lang: 0,
    }

}
</script>
<style lang="scss" scoped>
@import '../../assets/styles.scss';
.containertext{
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: 30px;

    &__img{
        width: 400px;
        min-height: 225px;
        background-position: center;
        background-size: cover;
        align-self: stretch;

    }

    &__text{
        width: calc(100% - 430px);
    }
}
.containertext[type="right"]{
    flex-direction: row-reverse;
}

.container{
    width: 100%;
    // height: fit-content;
    background-color: $background-color;
    
}
.textimg{
    width: 500px;
    display: block;
    padding: 20px 0px;
    margin-left: auto;
    margin-right: auto;
}
.special{
    margin-left: 0;
    
}
.txtbold{
    font-weight: 600;
}
.about{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
    align-items: center;
    height: 100%;

    // background-color: blue;

    &__img{
        height: 550px;
        border: solid 5px $primary-color;
        align-self: flex-start;
    }

    &__text{
        // background-color: red;

        &--title{
            color: $primary-color;
            font-size: $heading1;
            text-align: left;
            padding: 0px 0px 0px 0px;
            font-weight: 600;
        }
        &--content{
            text-align: justify;
            font-size: $paragraph;
            padding: 20px 0px 0px 0px;
        }

    }
}

@media only screen and (max-width: 910px){
   
    .containertext{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        &__img{
            margin: auto;
            width: 100%;
            max-width: 400px;
        }
        &__text{
            width: 100%;
        }
    }
    .containertext[type="right"]{
        flex-direction: column;
    }

    
}
// @media only screen and (max-width: 1000px){
   
//     .container{
//         padding: 40px 20px 40px 20px;
//     }
// }
// @media only screen and (max-width: 900px){
//     .about__text--content{
//         padding: 10px 0px;
//     }
// }
// @media only screen and (max-width: 650px){
//     .textimg{
//         width: 350px;
//         max-width: 100%;
//         display: block;
//         padding: 20px 0px;
//         margin-left: auto;
//         margin-right: auto;
//     }
// }
// @media only screen and (max-width: 900px){

//     .container{
//         justify-content: center;
//         align-items: center;
//     }
//     .about{
//         width: 100%;
//         flex-direction: column;
//         gap: 20px;

//         &__img{
//         height: 500px;
//         align-self: center;
//         }

//         &__text{
//             padding: 0;
//             &--title{
//                 font-size: $heading1;
//                 text-align: left;
//                 padding: 10px 0px;
//                 font-weight: 600;
//             }
//             &--content{
//                 text-align: left;
//                 font-size: 22px;
//                 padding: 10px 0px;
//             }

//         }
//     }
// }
</style>