<template>
    <transition name="fade">
        <Album v-if="albumActive" @closealbum="closeAlbum" :album="selectedGallery" />
    </transition>
    
    <main>
        <!-- <h1 class="title">社区照片</h1> -->
        <section class="links">
            <div class="links__node" v-if="lang == 2 || lang == 1" @click="openLink('http://www.ccbestlink.com/static/content/SQ/2022-09-22/1022598297413890048.html')">
                <div class="links__node--img" :style="{'background-image': 'url(http://www.ccbestlink.com/image/2022-09-22/thumb/1022597853727830016.jpg)', 'background-position': 'bottom'}" ></div>
                <article>
                <p class="links__node--date">2022-09-22</p>
                <p class="links__node--title">万锦市第二区市议员候选人WANG,YAN（王艳）积极倡导心理健康参加万锦市精神健康健步行活动</p>
                <p class="links__node--intro">9月18日，万锦市议员候选人WANG,YAN王艳参加了由Youth and Parents Association of Markham...</p>
                </article>
            </div>
            <div class="links__node" v-if="lang == 2 || lang == 1" @click="openLink('https://microwei.net/appshare/622749/1868259/6671/shareweb')">
                <div class="links__node--img" :style="{'background-image': 'url(https://img.weitt.us/content_cover/20220911/62dJLS_GTSCQpxYBnpUYoQ==.png)'}" ></div>
                <article>
                <p class="links__node--date">2022-09-12</p>
                <p class="links__node--title">开启邻里守望计划 共同推动社区安全 中秋佳节万锦二区举办居民同乐日大型活动</p>
                <p class="links__node--intro">孙瑞祥Jack Sun、许馨予报道 9月10日11时，万锦市二区数百居民兴高采烈来到Frisby Park...</p>
                </article>
            </div>
            <div class="links__node" v-if="lang == 2 || lang == 1" @click="openLink('https://microwei.net/appshare/621973/1865931/8469/shareweb')">
                <div class="links__node--img" :style="{'background-image': 'url(https://img.weitt.us/content_cover/8469/wlNmccoVRuOmhVrGdxLPXA==.png)'}" ></div>
                <article>
                <p class="links__node--date">2022-09-10</p>
                <p class="links__node--title">市议员候选人Yan Wang带你参加邻里守望居民同乐日</p>
                <p class="links__node--intro">本次社区集会活动由邻里守望互助会主办。活动将邀请约克警区警官进行社区安全讲座，问题答疑。</p>
                </article>
            </div>
            <div class="links__node" v-if="lang == 2 || lang == 1" @click="openLink('https://microwei.net/appshare/621500/1864512/5235/shareweb')">
                <div class="links__node--img" :style="{'background-image': 'url(https://img.weitt.us/temp/3400/iP6emKpiQkyfqFZB2IET-g==post_20220908122834_397.jpg)'}" ></div>
                <article>
                <p class="links__node--date">2022-09-08</p>
                <p class="links__node--title">责任与实干——根植社区的王艳</p>
                <p class="links__node--intro">今年的夏天格外的热，但华人参选万锦市议员的热情，比这夏天的热还热！在这群热情高涨的人群中，有一个身材矫健的女性的身影...</p>
                </article>
            </div>
            <div class="links__node" v-if="lang == 2 || lang == 1" @click="openLink('http://www.ccbestlink.com/static/content/SQ/2022-09-08/1017581445302333440.html')">
                <div class="links__node--img" :style="{'background-image': 'url(' + require('@/assets/images/yantstar.jpeg') + ')'}" style="background-size: contain; background-repeat: no-repeat"></div>
                <article>
                <p class="links__node--date">2022-09-08</p>
                <p class="links__node--title">万锦市第二区议员候选人王艳（Yan Wang）接受主流媒体采访</p>
                <p class="links__node--intro">王艳（YAN WANG），万锦市第二区议员候选人。</p>
                </article>
            </div>
            <div class="links__node" v-if="lang == 0" @click="openLink('https://www.thestar.com/local-markham/news/municipal-election/2022/09/06/markham-votes-ward-2-councillor-candidate-yan-wang.html')">
                <div class="links__node--img" :style="{'background-image': 'url(' + require('@/assets/images/portrait.png') + ')'}" style="background-position: top; background-size: contain; background-repeat: no-repeat"></div>
                <article>
                <p class="links__node--date">2022-09-06</p>
                <p class="links__node--title">MARKHAM VOTES: Ward 2 Councillor Candidate Yan Wang</p>
                <p class="links__node--intro">Here is a look at who is running for municipal office in the 2022 Markham municipal election...</p>
                </article>
            </div>
            <div class="links__node" v-if="lang == 2 || lang == 1" @click="openLink('https://microwei.net/appshare/620413/1861251/8469/shareweb')">
                <div class="links__node--img" style="background-image: url(https://img.weitt.us/temp/3574/yGXtC4lKSo6NyMjJJeTGMA==post_20220906003300_471.jpg)"></div>
                <article>
                <p class="links__node--date">2022-09-06</p>
                <p class="links__node--title">路遥知马力 日久见人心 值得你信赖的第二区候选人Yan Wang王艳</p>
                <p class="links__node--intro">这个世界上除了 “ 爱 ” 这个词之外，世界上最美的动词就是 “ 帮助 ” ，帮助别人就是一个人的人格品质...</p>
                </article>
            </div>
            <div class="links__node" v-if="lang == 2 || lang == 1" @click="openLink('https://mp.weixin.qq.com/s/znRTxs9ddyLWdlEax5lFMQ')">
                <div class="links__node--img" style="background-image: url(	https://img.weitt.us/temp/3574/s9xC4Y7lSaGLzz8XVJ7quA==post_20220831164539_378.jpg)"></div>
                <article>
                <p class="links__node--date">2022-08-31</p>
                <p class="links__node--title">YAN WANG 王艳：社区健康生活的倡导者</p>
                <p class="links__node--intro">8月27日上午9点， 沉寂两年的多伦多中国高校草地排球赛， 在万锦市8075 Bayview Ave再次拉开了帷幕。</p>
                </article>
            </div>
            <div class="links__node" v-if="lang == 2 || lang == 1" @click="openLink('https://microwei.net/appshare/617408/1852236/shareweb')">
                <div class="links__node--img" :style="{'background-image': 'url(' + require('@/assets/images/markhamprobevent.jpg') + ')'}"></div>
                <article>
                <p class="links__node--date">2022-08-30</p>
                <p class="links__node--title">万锦的事就是我们身边的事</p>
                <p class="links__node--intro">我是王艳，23年前，我和先生在万锦市安家，我们两个可爱的女儿在这里出生和长大。</p>
                </article>
            </div>
            <div class="links__node" v-if="lang == 2 || lang == 1" @click="openLink('https://microwei.net/appshare/616753/1850271/8469/shareweb')">
                <div class="links__node--img" style="background-image: url( https://img.weitt.us/content_cover/20220705/lLzSfZu-TfqX4P4QiXIbZw==.png)"></div>
                <article>
                <p class="links__node--date">2022-08-28</p>
                <p class="links__node--title">善良和爱心伴随 Yan Wang(王艳) 从商业精英到特殊教育工作者</p>
                <p class="links__node--intro">我是王艳，23年前，我和先生在万锦市安家，我们两个可爱的女儿在这里出生和长大。</p>
                </article>
            </div>
            <div class="links__node" v-if="lang == 2 || lang == 1" @click="openLink('https://microwei.net/appshare/603946/1811850/8469/shareweb')">
                <div class="links__node--img" style="background-image: url(https://img.weitt.us/temp/3428/IvdSO9vWSe-xQZchtblYhQ==ca9d23a7ee4fa67505a60c6a6dd02b39-sz_291361_20220725035319_375.jpg)"></div>
                <article>
                <p class="links__node--date">2022-07-28</p>
                <p class="links__node--title">真诚、坚持、实干、努力、果断、顾家、助人 万锦市第二选区市议员候选人王艳筹款晚宴成功举办</p>
                <p class="links__node--intro">万锦市第二选区（Markham Ward 2 )市议员候选人Yan Wang王艳竞选筹款晚宴于7月23日晚在“新世纪皇宫”举行。</p>
                </article>
            </div>
            <div class="links__node" v-if="lang == 2 || lang == 1" @click="openLink('http://www.hotspotnews.ca/portal.php?mod=view&aid=2119')">
                <div class="links__node--img" style="background-image: url(http://www.hotspotnews.ca/data/attachment/portal/202207/26/082751eq9wxs2qwlgf2gx2.jpg)"></div>
                <article>
                <p class="links__node--date">2022-07-25</p>
                <p class="links__node--title">希望给予和反哺 - 王艳竞选万锦市第二区的市议员</p>
                <p class="links__node--intro">万锦市第二选区(Markham Ward 2)市议员候选人Yan Wang 王艳竞选筹款晚宴于7月23日晚在新世纪皇宫举行。</p>
                </article>
            </div>
            <div class="links__node" v-if="lang == 2 || lang == 1" @click="openLink('http://newstarnet.com/magazine/%E5%85%85%E6%BB%A1%E6%9A%96%E6%84%8F%E5%92%8C%E6%B8%A9%E6%83%85%EF%BC%8C%E4%B8%87%E9%94%A6%E5%B8%82%E7%AC%AC%E4%BA%8C%E9%80%89%E5%8C%BA%E5%B8%82%E8%AE%AE%E5%91%98%E5%80%99%E9%80%89%E4%BA%BA%E7%8E%8B/')">
                <div class="links__node--img" :style="{'background-image': 'url(' + require('@/assets/images/wangyan0725.jpeg') + ')'}"></div>
                <article>
                <p class="links__node--date">2022-07-25</p>
                <p class="links__node--title">充满暖意和温情，万锦市第二区市议员候选人王艳举办筹款晚宴</p>
                <p class="links__node--intro">我是王艳，23年前，我和先生在万锦市安家，我们两个可爱的女儿在这里出生和长大。</p>
                </article>
            </div>
            <div class="links__node" v-if="lang == 2 || lang == 1" @click="openLink('https://microwei.net/appshare/593300/1779912/5235/shareweb')">
        
                <div class="links__node--img" :style="{'background-image': 'url(' + require('@/assets/images/yanreddress.jpeg') + ')'}" style="background-position: top;"></div>
                <article>
                <p class="links__node--date">2022-07-06</p>
                <p class="links__node--title">我眼中的政治素人王艳</p>
                <p class="links__node--intro">1999年移民加拿大后，我们在2001年曾为当时的华人议员黄志华做过竞选义工。</p>
                </article>
            </div>
            <div class="links__node" v-if="lang == 2 || lang == 1" @click="openLink('https://mp.weixin.qq.com/s/ynhB53z3IQOlyxOJoyN9Qg')">
                <div class="links__node--img" :style="{'background-image': 'url(' + require('@/assets/images/endorse1.jpeg') + ')'}"></div>
                <article>
                <p class="links__node--date">2022-06-20</p>
                <p class="links__node--title">我的花样姐姐邻居是社区名人</p>
                <p class="links__node--intro">前几天，一个国内群的年轻人问我，华人在海外是不是有“二等公民”的感觉？</p>
                </article>
            </div>
        </section>
        <!-- <section class="links" v-else>
            
        </section> -->
        <!-- <section v-else>
            <h1 style="text-align: center">COMING SOON!</h1>
        </section> -->
        <div class="line"></div>
        <section class="activities">
            <section class="album" @click="openAlbum('community')">
                <div class="album__preview"  :style="{'background-image': 'url('+require('@/assets/images/comm/community0.jpeg')+')'}">
                <div class="album__bg"></div>
                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" fill="#FFF"><path d="M11 36q-1.2 0-2.1-.9Q8 34.2 8 33V7q0-1.2.9-2.1Q9.8 4 11 4h12.25l3 3H43q1.2 0 2.1.9.9.9.9 2.1v23q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h32V10H25l-3-3H11v26Zm29.5 9H5q-1.2 0-2.1-.9Q2 40.2 2 39V10h3v29h35.5ZM16.7 27.45h20.6l-6.6-8.8-5.5 7.3-3.95-4.3ZM11 33V7v26Z"/></svg>
                </div>
                <article>
                    <h1>Community</h1>
                    <p>{{translate.comm[lang]}}</p>
                </article>
                
            </section>
            <section class="album" @click="openAlbum('media')">
                <div class="album__preview"  :style="{'background-image': 'url('+require('@/assets/images/media/media0.jpeg')+')'}">
                <div class="album__bg"></div>
                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" fill="#FFF"><path d="M11 36q-1.2 0-2.1-.9Q8 34.2 8 33V7q0-1.2.9-2.1Q9.8 4 11 4h12.25l3 3H43q1.2 0 2.1.9.9.9.9 2.1v23q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h32V10H25l-3-3H11v26Zm29.5 9H5q-1.2 0-2.1-.9Q2 40.2 2 39V10h3v29h35.5ZM16.7 27.45h20.6l-6.6-8.8-5.5 7.3-3.95-4.3ZM11 33V7v26Z"/></svg>
                </div>
                <article>
                    <h1>Media</h1>
                    <p>{{translate.media[lang]}}</p>
                </article>
            </section>
            <!-- <section class="album" @click="openAlbum('education')">
                <div class="album__preview"  :style="{'background-image': 'url('+require('@/assets/images/education/edu0.jpeg')+')'}"></div>
                <article>
                    <h1>Education</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Integer malesuada nunc vel risus commodo viverra maecenas.</p>
                </article>
            </section> -->
            <section class="album" @click="openAlbum('youth')">
                <div class="album__preview"  :style="{'background-image': 'url('+require('@/assets/images/youth/youth0.jpeg')+')'}">
                <div class="album__bg"></div>
                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48" fill="#FFF"><path d="M11 36q-1.2 0-2.1-.9Q8 34.2 8 33V7q0-1.2.9-2.1Q9.8 4 11 4h12.25l3 3H43q1.2 0 2.1.9.9.9.9 2.1v23q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h32V10H25l-3-3H11v26Zm29.5 9H5q-1.2 0-2.1-.9Q2 40.2 2 39V10h3v29h35.5ZM16.7 27.45h20.6l-6.6-8.8-5.5 7.3-3.95-4.3ZM11 33V7v26Z"/></svg>
                </div>
                <article>
                    <h1>Youth</h1>
                    <p>{{translate.sport[lang]}}</p>
                </article>
            </section>
            <!-- <section class="album" @click="openAlbum('sport')">
                <div class="album__preview"  :style="{'background-image': 'url('+require('@/assets/images/sports/sport0.jpeg')+')'}"></div>
                <article>
                    <h1>Sports</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Integer malesuada nunc vel risus commodo viverra maecenas.</p>
                </article>
            </section> -->
        </section>



        
    </main>
</template>

<script>
import Album from '@/components/community/Album.vue';
import translate from '@/assets/translate.json';

export default {
    name: "Questions",
    props:{
        lang: 0,
    },
    components:{
        Album
    },
    data(){
        return{
            albumActive: false,
            selectedGallery: "community",
            translate: translate.album
        }
    },
    
    methods:{
        openLink(link){
            window.open(link)
        },
        closeAlbum(){
            this.albumActive = false;
        },
        openAlbum(gal){
            this.albumActive = true;
            this.selectedGallery = gal;
        }
    }
}
</script>

<style scoped lang="scss">
@import '@/assets/styles.scss';
main{
    
}
.title{
    text-align: center;
}
.divider{
    width: 100%;
    height: 5px;
    background-color: $background-color;
    margin-top: 30px;
}
.line{
    width: 100%; 
    height: 2px; 
    background-color: $primary-color; 
    margin-top: 30px; 
    margin-bottom: 30px

}
h1{
    color: $primary-color;
    text-align: left;
    font-size: $heading1;
    margin-top: 30px;
}
p{
    text-align: left;

}
.links{
    width: fit-content;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    background-color: none;

    &__node{
        width: calc((100%));

        gap: 30px;
        display: flex;
        // padding: 20px;
        background-color: none;
        // box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.128);
        transition: all .3s;

        & > article{
            width: calc(100% - 430px);
        }
        &--img{
            display: block;
            width: 400px;
            height: 250px;
            background-size: cover;
            background-position: center;
        }

        &--date{
            font-size: 15px;
            color: rgb(75, 74, 72);
            margin-top: 10px;

        }


        &--title{
            font-size:1.2em;
            margin-top: 0px;
            font-weight: 600;
            color: black;
        }

        &--intro{
            font-size: 18px;
            color: black;
            margin-top: 5px;
        }

        &:hover &--title{
            color: $primary-color;
            
        }
        &:hover &--img{
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);

        }
        &:hover{
            cursor: pointer;
            // box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.153);

        }
   }
}


.album{
    display: flex;
    gap: 30px;
    margin-top: 20px;
    &:hover{
        cursor: pointer;
    }
    &__bg{
        background-color: rgba(29, 29, 29, 0.405);
        width: 100%;
        height: 100%;
    }
    &__preview{
        width: 400px;
        height: 250px;
        position: relative;
        // filter: brightness(80%);
        background-size: cover;
        background-position: center;

        & > svg{
            position: absolute;
            bottom: 20px;
            right: 20px;
            // transform: scale(1);
            

            & > *{
                width:  100%;
                height: 100%;
                fill: white;

            }
            
        }
        
    }

    & > article{
  
        width: calc(100% - 430px);
    }
}

@media only screen and (max-width: 900px){
    .activities{
       
    }
    .links{
        &__node{
            display: block;

            &--img{
                width: 100%;
                max-width: 500px;
            }

            & > article{
                width: 100%;
                max-width: 500px;
            }
        }
    }
    .album{
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
        margin-top: 30px;
        &__preview{
            width: 100%;
            max-width: 500px;
        }
        & > article{
            width: 100%;
            max-width: 500px;
            & > h1{
                margin-top: 10px;
            }
        }

    }
}


@media only screen and (max-width: 700px){
    .links{
        display: block;

        &__node{
            width: 100%;
            margin-top: 40px;

            &--intro{
                display: none;
            }
        }
    }
}



.fade-enter-from{
    opacity: 0;
}
.fade-enter-to{
    opacity: 1;
}
.fade-enter-active, .fade-leave-active{
    -webkit-transition: opacity 500ms ease;
    -webkit-transition: -webkit-transform 500ms;
    -moz-transition: opacity 500ms ease;
    transition: opacity 0.5s ease;
    
}

.fade-leave-from{
    opacity: 1;
}
.fade-leave-to{
    opacity: 0;
}

</style>