<template>
    <main class="content-padding" id="mainsection">
        <section class="wrap">
        
        <!-- <div>
            <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Applefest is an annual tradition at <a href="https://twitter.com/MarkhamMuseum?ref_src=twsrc%5Etfw">@markhammuseum</a> and is the tastiest event of the year! <br><br>Interacting with the youth is so enjoyable. More outdoor activities are definitely beneficial to everyone’s mental health<a href="https://twitter.com/frankscarpitti?ref_src=twsrc%5Etfw">@frankscarpitti</a> <a href="https://twitter.com/Amanda_Collucci?ref_src=twsrc%5Etfw">@Amanda_Collucci</a> <a href="https://t.co/h6NER6hEY4">pic.twitter.com/h6NER6hEY4</a></p>&mdash; Yan Wang (@yanwangmarkham) <a href="https://twitter.com/yanwangmarkham/status/1574107909839093760?ref_src=twsrc%5Etfw">September 25, 2022</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        </div> -->
        <div>
        <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Mental Health Awareness Walkathon organized by <a href="https://twitter.com/ypam?ref_src=twsrc%5Etfw">@YPAM</a> and <a href="https://twitter.com/connectinggta?ref_src=twsrc%5Etfw">@connectinggta</a>, encouraging people to break barriers around mental health and to seek help in the same way that they would for a physical ailment. <a href="https://t.co/iBiVEjNUwY">pic.twitter.com/iBiVEjNUwY</a></p>&mdash; Yan Wang (@yanwangmarkham) <a href="https://twitter.com/yanwangmarkham/status/1571712281200803841?ref_src=twsrc%5Etfw">September 19, 2022</a></blockquote>
        </div>
        
        <div>
        <blockquote class="twitter-tweet" width="325px" height="480px"><p lang="en" dir="ltr">Dear Drivers,<br>Our kids go to school here. Please limit your speed to 40km/hr or below when passing schools, at all times, at all school zones.<br>Drive safe!<br>Yan Wang <a href="https://t.co/mLVrfb6VNI">pic.twitter.com/mLVrfb6VNI</a></p>&mdash; Yan Wang (@yanwangmarkham) <a href="https://twitter.com/yanwangmarkham/status/1565913999979544576?ref_src=twsrc%5Etfw">September 3, 2022</a></blockquote> 
        </div>
        <!-- <iframe src="" frameborder="0" height="200" width="300">
            <blockquote class="twitter-tweet"><p lang="en" dir="ltr">Dear Drivers,<br>Our kids go to school here. Please limit your speed to 40km/hr or below when passing schools, at all times, at all school zones.<br>Drive safe!<br>Yan Wang <a href="https://t.co/mLVrfb6VNI">pic.twitter.com/mLVrfb6VNI</a></p>&mdash; Yan Wang (@yanwangmarkham) <a href="https://twitter.com/yanwangmarkham/status/1565913999979544576?ref_src=twsrc%5Etfw">September 3, 2022</a></blockquote> <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
        </iframe> -->

        <div>
        <blockquote class="twitter-tweet" width="325px" height="480px"><p lang="en" dir="ltr">Honored to be invited to attend the Summer Festival hosted by FCCM, I am glad to be able to share the concerns I collected and the questions I was asked during canvassing with the Mayor, and the current City Councilors. Talks are to be continued. <a href="https://t.co/bcw64DbZz6">pic.twitter.com/bcw64DbZz6</a></p>&mdash; Yan Wang (@yanwangmarkham) <a href="https://twitter.com/yanwangmarkham/status/1564126791245127681?ref_src=twsrc%5Etfw">August 29, 2022</a></blockquote> 
        </div>

        <div>
        <blockquote class="twitter-tweet" width="325px" height="480px"><p lang="en" dir="ltr">The children of this mother go to the same school as my daughters did. I can totally relate to her concerns about the community safety. <a href="https://twitter.com/hashtag/streetlarking?src=hash&amp;ref_src=twsrc%5Etfw">#streetlarking</a> <a href="https://twitter.com/hashtag/communitysafety?src=hash&amp;ref_src=twsrc%5Etfw">#communitysafety</a> <a href="https://twitter.com/hashtag/neighbourhoodsafety?src=hash&amp;ref_src=twsrc%5Etfw">#neighbourhoodsafety</a> <a href="https://t.co/qjrexJUig7">pic.twitter.com/qjrexJUig7</a></p>&mdash; Yan Wang (@yanwangmarkham) <a href="https://twitter.com/yanwangmarkham/status/1559724792139264001?ref_src=twsrc%5Etfw">August 17, 2022</a></blockquote> 
        </div>

        <div>
        <blockquote class="twitter-tweet" width="325px" height="480px"><p lang="en" dir="ltr">I got asked often when I talked with the residents in Ward 2, that how high can one build their fences. And here is my research. Front yard - Up to 1.2m (4’) high. Backyard - up to 1.8m (6’) high Corner properties, a fence along the exterior flank yard - up to 1.2m (4’) high <a href="https://t.co/Tm8KmGH7oE">pic.twitter.com/Tm8KmGH7oE</a></p>&mdash; Yan Wang (@yanwangmarkham) <a href="https://twitter.com/yanwangmarkham/status/1557507038623813633?ref_src=twsrc%5Etfw">August 10, 2022</a></blockquote> 
        </div>

        <div>
        <blockquote class="twitter-tweet" width="325px" height="480px"><p lang="en" dir="ltr">I am listening, affordable-housing tenants!<a href="https://twitter.com/hashtag/affordablehousing?src=hash&amp;ref_src=twsrc%5Etfw">#affordablehousing</a> <a href="https://twitter.com/hashtag/markhamvotes?src=hash&amp;ref_src=twsrc%5Etfw">#markhamvotes</a> <a href="https://twitter.com/hashtag/markhamward2?src=hash&amp;ref_src=twsrc%5Etfw">#markhamward2</a><a href="https://twitter.com/hashtag/municipaleselection2022?src=hash&amp;ref_src=twsrc%5Etfw">#municipaleselection2022</a> <a href="https://t.co/eDF3WaipHo">pic.twitter.com/eDF3WaipHo</a></p>&mdash; Yan Wang (@yanwangmarkham) <a href="https://twitter.com/yanwangmarkham/status/1557396026746617856?ref_src=twsrc%5Etfw">August 10, 2022</a></blockquote> 
        </div>

        <div>
        <blockquote class="twitter-tweet" width="325px" height="480px"><p lang="en" dir="ltr">Got invited to join my dear friends from Paradise Seniors Association Markham at their Summer Gathering. They are incredibly talented and energetic! <a href="https://t.co/3bW2A6M1eD">pic.twitter.com/3bW2A6M1eD</a></p>&mdash; Yan Wang (@yanwangmarkham) <a href="https://twitter.com/yanwangmarkham/status/1557177207595974656?ref_src=twsrc%5Etfw">August 10, 2022</a></blockquote> 
        </div>
        </section>
    </main>
</template>
<script>

export default {
    name: 'Social',
    mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
      document.head.appendChild(recaptchaScript);


      const scrollContainer = document.querySelector("#mainsection");

        scrollContainer.addEventListener("wheel", (evt) => {
            evt.preventDefault();
            scrollContainer.scrollLeft += evt.deltaY;
        });
    },

}
</script>
<style lang="scss" scoped>
@import '@/assets/styles.scss';

main{
    overflow: scroll;
    background-color: $background-color;
}

.wrap{
    display: flex;
    height: fit-content;
    // background-color: $background-color;
    // flex-flow: column wrap;
    width: fit-content;
    overflow: auto;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    align-items: flex-start;
    // flex-wrap: wrap;
    
    // overflow: scroll;

    & > *{
        display: block;
        width: 325px;
        // width: fit-content;
        height: fit-content;
        overflow: hidden;
        // width: calc((100% - 40px) / 3);
    }
}
// iframe{
//     height: 480px;
// }
blockquote.twitter-tweet{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 300px;
}

// @media only screen and (max-width: 1100px) {
//     main{
//         justify-content: center;
//         align-items: flex-start;
//         & > *{
//             width: 286px;
//         }
//     }
// }
</style>