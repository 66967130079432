<template>
    <main class="content-padding">
        <h2>Paper ballot during Early Voting Days</h2>
        <p>October 20, 21 & 22</p>
        <p>10AM to 8PM EDT</p>
        <p>Vote in person at any Voting Place in Markham</p>

        <table class="tableone">
            <tr class="tableone__row">
                <th>Location</th>
                <th>Address</th>
            </tr>
            <tr class="tableone__row">
                <td>Angus Glen Community Centre</td>
                <td>3990 Major Mackenzie Dr E, Markham, ON L6C 1P8</td>
            </tr>
            <tr class="tableone__row">
                <td>Centennial Community Centre</td>
                <td>8600 McCowan Rd, Unionville, ON, L3P 3M2</td>
            </tr>
            <tr class="tableone__row">
                <td>Thornhill Community Centre</td>
                <td>7755 Bayview Ave, Thornhill, ON L3T 4P1</td>
            </tr>
            <tr class="tableone__row">
                <td>Armadale Community Centre</td>
                <td>2401 Denison St, Markham, ON L3S1G3</td>
            </tr>
            <tr class="tableone__row">
                <td>Aaniin Community Centre</td>
                <td>5665 14th Ave, Markham, ON L3S 3K5</td>
            </tr>
            <tr class="tableone__row">
                <td>Cornell Community Centre</td>
                <td>3201 Bur Oak Ave, Markham, ON, L6B 0T2</td>
            </tr>
        </table>
        <div style="height: 50px"></div>
        <h2>In-person touchscreen on Final Voting Day</h2>
        <p>October 24</p>
        <p>10AM to 8PM EDT</p>
        <p>Vote in person at any Voting Place in Markham</p>

        <table class="tableone">
            <tr class="tableone__row">
                <th>Location</th>
                <th>Address</th>
            </tr>
            <tr class="tableone__row">
                <td>Flato Markham Theatre</td>
                <td>171 Town Centre Blvd, Markham, ON L3R 8G5</td>
            </tr>
            <tr class="tableone__row">
                <td>Angus Glen Community Centre</td>
                <td>3990 Major Mackenzie Dr E, Markham, ON L6C 1P8</td>
            </tr>
            <tr class="tableone__row">
                <td>Varley Art Gallery of Markham</td>
                <td>216 Main St Unionville, Unionville, ON L3R 2H1</td>
            </tr>
            <tr class="tableone__row">
                <td>Centennial Community Centre</td>
                <td>8600 McCowan Rd, Unionville, ON L3P 3M2</td>
            </tr>
            <tr class="tableone__row">
                <td>Thornhill Community Centre</td>
                <td>7755 Bayview Ave, Thornhill, ON L3T 4P1</td>
            </tr>
            <tr class="tableone__row">
                <td>Markham Museum</td>
                <td>9350 Markham Rd, Markham, ON L3P 3J3</td>
            </tr>
            <tr class="tableone__row">
                <td>Armadale Community Centre</td>
                <td>2401 Denison St, Markham, ON L3S 1G3</td>
            </tr>
            <tr class="tableone__row">
                <td>Aaniin Community Centre</td>
                <td>5665 14th Ave, Markham, ON L3S 3K5</td>
            </tr>
            <tr class="tableone__row">
                <td>Markham Village Library 17</td>
                <td>6031 Hwy7, Markham, ON L3P 3A7</td>
            </tr>
            <tr class="tableone__row">
                <td>Cornell Community Centre</td>
                <td>3201 Bur Oak, Markham, ON L6B 0T2</td>
            </tr>
        </table>
        
    </main>
</template>
<script>
export default {
    name: "Address",
    props:{
        lang: 0
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles.scss';


.tableone{
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    border: solid 4px $primary-color;
    &__row{
        border: none;
        & > td{
            text-align: left;
            padding: 5px 10px;
            border-left: solid 4px $primary-color;
        }
        & > th{
            background-color: $primary-color;
            color: white;
            border-left: solid 4px $primary-color;
            border-bottom: solid 4px $primary-color;
        }
    }
    &__row:nth-child(odd){
        background-color: $title-color;
    }
    &__row:nth-child(even){
        background-color: white;
    }
}
</style>