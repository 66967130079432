<template>
    <section class="container content-padding">

        <!-- <h1 class="about__text--title">吾心安处，便是吾乡.</h1> -->
        <section class="containertext">
            <div class="containertext__img" :style="{'background-image': 'url(' + require('@/assets/images/family1.jpeg') + ')'}"></div>
            <div class="containertext__text">
                <h1 class="about__text--title">我爱万锦，我爱我家</h1>
                <p class="about__text--content">大家好，我是万锦市第2区市议员候选人，王艳。23年前，我和先生远涉重洋移民加国，选择在万锦市定居。</p>
                <!-- <p class="about__text--content"></p> -->
                <p class="about__text--content">和无数个新移民家庭一样，我们在兼顾家庭的同时，创办了自己的贸易公司积极在商界发展。随着两个女儿的相继出生，我开始转入家庭，用更多的时间去陪伴她们成长。从大女儿走进学校起，我开始了支持女儿班级和学校活动的义工生涯。正是这些义工机会，让我体会到自己对特殊教育的热爱，成为了一名特殊教育工作者。</p>
            </div>
        </section>

        <!-- <p class="about__text--content">随着两个女儿的相继出生，我开始转入家庭，用更多的时间去陪伴她们成长。从大女儿走进学校起，我开始了支持女儿班级和学校活动的义工生涯。正是这些义工机会，让我体会到自己对特殊教育的热爱，成为了一名特殊教育工作者。</p> -->
         
        <div style="width: 100%; height: 25px"></div>

         <section class="containertext" type="right">
            <div class="containertext__img" :style="{'background-image': 'url(' + require('@/assets/images/yanteacher.png') + ')'}"></div>
            <div class="containertext__text">
                <h1 class="about__text--title">从自主创业到从事特殊教育，从主持人到专业媒体人，从志愿者到社区倡导者</h1>
                <p class="about__text--content">从约克区教育局，到杜兰区天主教教育局和杜兰区教育局，再到多伦多教育局，在服务众多从学前班到高中各年级的有特殊需要的学生群体时，我深刻了解到每个个体独特的需求和境遇。在处理每个独特复杂的个案时，以爱为初心，以关怀和关注为己任，运用丰富的专业知识，努力帮助他（她）们成长和改变。近10年的特殊教育经历，让我深刻体会特殊教育的重要性和从事这项工作的意义所在。</p>
            </div>
        </section>
        
        <div style="width: 100%; height: 25px"></div>
        
        <section class="containertext" type="left">
            <div class="containertext__img" :style="{'background-image': 'url(' + require('@/assets/images/aboutleader.jpg') + ')'}"></div>
            <div class="containertext__text">
                
                <p class="about__text--content">正是服务特殊群体的这些经历，更加激励我加入各种志愿者团队，深入社区，服务民众。我自2015年起担任华侨大学加拿大校友会秘书长，领导团队组织各种大型体育文娱活动，积极参与各类社会公益事业，把“矢志服务成就美丽人生”的志愿者理念根植我心。</p>
                <!-- <p class="about__text--content">2017年 ，我加盟“魅力中国” 国语电台， 成为一名专业电台节目主持人。 通过与行业专家和社区人士的空中交流，共同分享加拿大多元文化带来的自由民主和社会融合。</p> -->
                
            </div>
        </section>

        <div style="width: 100%; height: 25px"></div>

        <section class="containertext" type="right">
            <div class="containertext__img" :style="{'background-image': 'url(' + require('@/assets/images/endorse1.jpeg') + ')'}"></div>
            <div class="containertext__text">
                
                
                <p class="about__text--content">2017年，我加盟“魅力中国” 国语电台，成为一名专业电台节目主持人。 通过与行业专家和社区人士的空中交流，共同分享加拿大多元文化带来的自由民主和社会融合。</p>
                <p class="about__text--content">作为媒体工作者，我跟踪报道历次三级政府的选举和社会时政，深入了解政府运作机制，充分体察民情民愿。历经岁月浸染，阅尽百态人生，我深刻体会到，四方奔忙抵不过一捧人间烟火。热爱家庭，服务他人，回报社会，才不枉一场丰盈的人生。</p>
            </div>
        </section>

        
        <div style="width: 100%; height: 25px"></div>
       
        
        
        <p class="about__text--content txtbold" style="text-align: center">我是王艳，万锦市第2区市议员候选人，<br>未忘初衷，未负流年<br>胸中万千丘壑，眼里星辰大海<br>此刻，时光正好</p>  
       
        <p class="about__text--content txtbold" style="font-size: 30px; text-align: center">选我，定不负您的期望！ </p>    
    </section>
</template>
<script>
export default {
    name: "AboutMe",
    data(){
        return{
            aboutme: ["About Me", "關於我", "关于我"]
        }
    },
    props: {
        lang: 0,
    }

}
</script>
<style lang="scss" scoped>
@import '../../assets/styles.scss';
.containertext{
    display: flex;
    align-items: center;
    justify-content: stretch;
    gap: 30px;

    &__img{
        width: 400px;
        min-height: 225px;
        background-position: center;
        background-size: cover;
        align-self: stretch;

    }

    &__text{
        width: calc(100% - 430px);
    }
}
.containertext[type="right"]{
    flex-direction: row-reverse;
}

.container{
    width: 100%;
    // height: fit-content;
    background-color: $background-color;
    
}
.textimg{
    width: 500px;
    display: block;
    padding: 20px 0px;
    margin-left: auto;
    margin-right: auto;
}
.special{
    margin-left: 0;
    
}
.txtbold{
    font-weight: 600;
}
.about{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
    align-items: center;
    height: 100%;

    // background-color: blue;

    &__img{
        height: 550px;
        border: solid 5px $primary-color;
        align-self: flex-start;
    }

    &__text{
        // background-color: red;

        &--title{
            color: $primary-color;
            font-size: $heading1;
            text-align: left;
            padding: 0px 0px 0px 0px;
            font-weight: 600;
        }
        &--content{
            text-align: justify;
            font-size: $paragraph;
            padding: 20px 0px 0px 0px;
        }

    }
}

@media only screen and (max-width: 910px){
   
    .containertext{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;

        &__img{
            margin: auto;
            width: 100%;
            max-width: 400px;
        }
        &__text{
            width: 100%;
        }
    }
    .containertext[type="right"]{
        flex-direction: column;
    }

    
}
// @media only screen and (max-width: 1000px){
   
//     .container{
//         padding: 40px 20px 40px 20px;
//     }
// }
// @media only screen and (max-width: 900px){
//     .about__text--content{
//         padding: 10px 0px;
//     }
// }
// @media only screen and (max-width: 650px){
//     .textimg{
//         width: 350px;
//         max-width: 100%;
//         display: block;
//         padding: 20px 0px;
//         margin-left: auto;
//         margin-right: auto;
//     }
// }
// @media only screen and (max-width: 900px){

//     .container{
//         justify-content: center;
//         align-items: center;
//     }
//     .about{
//         width: 100%;
//         flex-direction: column;
//         gap: 20px;

//         &__img{
//         height: 500px;
//         align-self: center;
//         }

//         &__text{
//             padding: 0;
//             &--title{
//                 font-size: $heading1;
//                 text-align: left;
//                 padding: 10px 0px;
//                 font-weight: 600;
//             }
//             &--content{
//                 text-align: left;
//                 font-size: 22px;
//                 padding: 10px 0px;
//             }

//         }
//     }
// }
</style>